import { render, staticRenderFns } from "./AppText.vue?vue&type=template&id=5d216b9f&scoped=true&functional=true&"
import script from "./AppText.vue?vue&type=script&lang=js&"
export * from "./AppText.vue?vue&type=script&lang=js&"
import style0 from "./AppText.vue?vue&type=style&index=0&id=5d216b9f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "5d216b9f",
  null
  
)

export default component.exports