<template>
	<div class="app-tabs">
		<div
			v-for="item in list"
			:key="item[itemValue]"
			class="app-tabs__item"
			:class="{ 'app-tabs__item--active': item[itemValue] === value }"
			@click="select(item[itemValue])"
		>
			{{ item[itemName] }}
		</div>
	</div>
</template>

<script>
import "@/assets/styles/components/app-tabs.scss";

export default {
	name: "AppTabs",
	props: {
		list: Array,
		itemValue: {
			type: String,
			default: "value",
		},
		itemName: {
			type: String,
			default: "name",
		},
		value: {
			type: [String, Number],
			default: "",
		},
	},
	methods: {
		select(value) {
			this.$emit("input", value);
		},
	},
};
</script>
